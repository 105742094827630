import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../style/styles.css'
export default function AutofundFeature() {
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <Box
      w={{ xl: '1300px', base: 'full' }}
      mx="auto"
      px={{ base: '2rem', md: '4rem', lg: '6rem' }}
      pt={{ lg: 20, base: 20 }}
      data-aos="fade-up"
      data-aos-duration="1000">
      <Flex
        align={'center'}
        justifyContent="space-between"
        direction={{ lg: 'row', base: 'column-reverse' }}>
        <Image
          src="/images/wallet.png"
          w={{ sm: '600px', base: '100%' }}
          h={{ sm: '600px', base: '100%' }}
          my={{ lg: 0, base: 8 }}
          objectFit="contain"
        />
        <Flex direction={'column'} gap={8}>
          <Box lineHeight={'shorter'}>
            <h1 className="take-charge ">
              P.S, you can <br />
              <span style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>
                automatically fund <br />
              </span>
              your bill plans
            </h1>
          </Box>
          <Text
            maxW={{ sm: '500px', base: 'full' }}
            color={'#4A485F'}
            fontSize={{ base: '16px', md: '18px' }}
            textAlign={{ lg: 'start', base: 'center' }}>
            Use the auto-save feature to fund your plan at convenient intervals and avoid putting
            you or your business in a tight spot with upcoming bills.
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}
