import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react'
import Image from 'next/image'
import React from 'react'
import { colors } from 'styles/foundations'

export default function CustomersComments() {
  const data = [
    {
      id: 1,
      text: 'I’ve always needed reminders to pay the cleaners and other service workers which was embarrassing. With Lint, this is no longer an issue. My payments are scheduled to go out at timed intervals, giving me the bandwidth to focus on more productive endeavours.',
      title: 'Michael Anya',
      status: 'Head of Product, Instill Education',
      img: '/images/customer/av03.png'
    },
    {
      id: 2,
      text: 'Lint has revolutionized the way we manage bills for our short-lets. Its seamless payment system ensures all bills & salaries are disbursed on time, making the process effortless and stress-free. Paying bills has never been easier.',
      title: 'Elizabeth Adiwu',
      status: 'Manager, Xenial bookings',
      img: '/images/customer/av06.png'
    },
    {
      id: 3,
      text: 'Lint has transformed my approach to financial management, making it easy to schedule bill payments and transfer funds to my savings. Its user-friendly interface and reliability have significantly simplified my financial routine.',
      title: 'Kiesem Senlong',
      status: 'Software developer, Test Gorilla',
      img: '/images/customer/av05.png'
    },
    {
      id: 4,
      text: "I love using Lint! Its efficiency and convenience have transformed the way I manage my bills. I can effortlessly set aside funds for my bills, avoiding the temptation to overspend and ensuring I never miss payments. It's  a game-changer for ensuring I stick to my budget and hit my financial goals! ",
      title: 'Blipnanret Gutap',
      status: 'Founder, Smile accounting',
      img: '/images/customer/av01.png'
    },
    {
      id: 5,
      text: 'When it comes to automated payments, I give it to Lint. We’ve been paying our staff with the automated feature on the Lint app and we’ve never had issues with missing payments. I strongly recommend individuals and businesses use Lint for managing bills and salaries.',
      title: 'Joel Esiebo',
      status: 'COO, MeBrandingYou',
      img: '/images/customer/av01 (1).png'
    },
    {
      id: 6,
      text: 'From signup to daily use to the user interface of the App it is obvious that it was intentionally built and I give kudos to the developers for that. I strongly recommend for every individual, businesses and SMEs to make use of the App for managing bill payments and salary payments.',
      title: 'Bek Jang',
      status: 'Founder',
      img: '/images/customer/av04.png'
    }
  ]
  return (
    <Box bg={colors.brand['surface/dim']}>
      <Box
        w={{ xl: '1100px', base: 'full' }}
        mt={20}
        mx="auto"
        px={{ base: '2rem', md: '4rem', xl: '0px' }}
        py={{ lg: '100px', base: '50px' }}
        data-aos="fade-up"
        data-aos-duration="1000">
        <Heading textAlign={'center'} fontSize={{ md: '48px', base: '32px' }}>
          What our customers say
        </Heading>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap={6}
          mt={20}>
          {data.map((inf) => (
            <Flex
              direction={'column'}
              gap={6}
              bg={colors.brand.white}
              borderRadius={'12px'}
              h="312px"
              justifyContent="space-between"
              p={10}
              key={inf.id}>
              <Text>{inf.text}</Text>
              <Box display={'flex'} alignItems={'center'} gap={5}>
                <Image
                  src={inf.img}
                  style={{ objectFit: 'contain' }}
                  width={50}
                  height={50}
                  alt={inf.title}
                />
                <Box>
                  <Text fontWeight={'semibold'} fontSize={'18px'}>
                    {inf.title}
                  </Text>
                  <Text fontSize={'14px'}>{inf.status}</Text>
                </Box>
              </Box>
            </Flex>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}
