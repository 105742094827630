import FeaturesStripe from './features-stripe'
import PlansSection from './plans-section'
import DownloadApp from './download-app-section'
import GoogleAuth from './google-auth'
import PaymentSetups from './payment-setups'
import NotificationFeature from './notification-feature'
import CustomersComments from './customers-comments'
import AutofundFeature from './autofund-feature'
import UtilityCards from './utility-cards'
import MobileFeatured from './MobileFeatured'
export {
  FeaturesStripe,
  PlansSection,
  DownloadApp,
  GoogleAuth,
  PaymentSetups,
  NotificationFeature,
  CustomersComments,
  AutofundFeature,
  UtilityCards,
  MobileFeatured
}
