import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../style/styles.css'
import Image from 'next/image'
export default function NotificationFeature() {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 500,
      easing: 'ease-in-out',
      delay: 100,
      once: false
    })
  }, [])

  return (
    <Box
      w={{ xl: '1300px', base: 'full' }}
      mx="auto"
      px={{ base: '2rem', md: '4rem', lg: '6rem' }}
      pt={{ lg: 0, base: 20 }}>
      <Flex
        justifyContent="space-between"
        direction={{ lg: 'row', base: 'column-reverse' }}
        alignItems={'center'}
        w="full">
        <Box display={'flex'} justifyContent={'center'} alignItems="center" alignContent={'center'}>
          <Image width={620} height={600} alt="upcoming" src="/images/upcoming.png" />
        </Box>
        <Flex direction={'column'} gap={8}>
          <Box lineHeight={'shorter'}>
            <h1 className="take-charge ">
              <span style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>Stay alert </span> with
              our <br /> handy notification <br /> feature!
            </h1>
          </Box>

          <Text
            color={'#4A485F'}
            maxW={{ sm: '500px', base: 'full' }}
            fontSize={{ base: '16px', md: '18px' }}
            textAlign={{ lg: 'start', base: 'center' }}>
            Get reminders about upcoming payments so you can easily double-check. Get transaction
            receipts and electricity tokens in your mail.
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}
